<template>
  <auth2-fa></auth2-fa>
</template>
<script>
import Auth2Fa from "@components/shared/Auth2Fa";
export default {
  components: {
    Auth2Fa
  }
}
</script>